//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { ResourceService } from "../../../services/resource.service";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-bulk-resource-edit-stock")
@Component({
    selector: "s25-ng-bulk-resource-edit-stock",
    template: `
        <div *ngIf="this.init" class="c-margin-top--single">
            <label class="ngBold">Stock Totals:</label>
            <s25-ng-editable-number
                [min]="0"
                [allowEmpty]="true"
                [alwaysEditing]="true"
                [(val)]="this.val"
            ></s25-ng-editable-number>

            <label class="c-margin-top--single ngBold" for="date-1"> Start Date:</label>
            <s25-datepicker
                [inline]="false"
                [(modelValue)]="this.modelValue"
                [prefType]="'U'"
                (modelValueChange)="onChangeDate($event, 'startDate')"
            ></s25-datepicker>

            <label class="ngBold" for="date-2">End Date:</label>
            <s25-datepicker
                [inline]="false"
                [(modelValue)]="this.modelValue"
                [prefType]="'U'"
                (modelValueChange)="onChangeDate($event, 'endDate')"
            ></s25-datepicker>

            <s25-ng-bulk-edit-save [submitFn]="saveFn" [buttonText]="'Update Stock Totals'"></s25-ng-bulk-edit-save>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkResourceEditStockComponent implements OnInit {
    @Input() itemIds: number[];
    @Input() itemTypeId: number = 2;
    @Input() itemType: string;
    val: number;
    init: boolean;
    modelBean: any;
    modelValue: any = {
        date: new Date(),
        showToday: true,
    };
    startDate = new Date();
    endDate = new Date();

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit(): void {
        this.init = true;
        this.cd.detectChanges();
    }

    onChangeDate(e: any, date: any) {
        date === "startDate" ? (this.startDate = e) : (this.endDate = e);
        this.modelValue.showToday = true;
    }

    get saveFn() {
        return this.save.bind(this);
    }

    save() {
        let alertArr = [];
        if (!this.val || !Number.isInteger(this.val)) {
            alertArr.push(alert("Please enter a valid integer."));
        }
        if (S25Util.date.parse(this.startDate) >= S25Util.date.parse(this.endDate)) {
            alertArr.push(alert("Start date must be after the end date."));
        }

        if (alertArr.length === 0) {
            return ResourceService.updateStock(this.itemIds, this.val, this.startDate, this.endDate);
        }
    }
}
