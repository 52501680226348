//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { SpaceService } from "../../../services/space.service";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { S25Datefilter } from "../../s25-dateformat/s25.datefilter.service";
import { TimepickerApi } from "../../s25-timepicker/timepicker.api";

@TypeManagerDecorator("s25-ng-bulk-location-edit-hours")
@Component({
    selector: "s25-ng-bulk-location-edit-hours",
    template: `
        <div *ngFor="let h of hours; let i = index" class="c-margin-bottom--half c-margin-top--single">
            <div>
                <div class="ngBold">{{ h.day }}</div>
                <s25-timepicker
                    class="ngInlineBlock"
                    useAllHours="true;"
                    step="30"
                    [inputId]="h.openId"
                    [prefType]="this.prefType"
                    [(modelValue)]="h.open"
                    (modelValueChange)="this.onChangeTime($event, h.openId, i)"
                >
                </s25-timepicker>
                <span> - </span>
                <s25-timepicker
                    class="ngInlineBlock"
                    step="30"
                    useAllHours="true;"
                    [inputId]="h.closeId"
                    [prefType]="this.prefType"
                    [(modelValue)]="h.close"
                    (modelValueChange)="this.onChangeTime($event, h.closeId, i)"
                >
                </s25-timepicker>
                <span class="c-margin-left--single ngBold" (mouseover)="onMouseOver(i)">
                    <label for="update{{ i }}" (change)="onUpdateChange($event, i)">
                        <input type="checkbox" id="update{{ i }}" [(ngModel)]="h.update" /> Update</label
                    >
                </span>
            </div>
            <div>
                <button class="aw-button aw-button--outline c-margin-top--single" (click)="close(i)">Close</button>
            </div>
        </div>

        <div *ngIf="this.isTimeError" class="ngRed ngBold c-margin-bottom--single" role="alert">
            Close time can't be before open time.
        </div>

        <s25-ng-bulk-edit-save [submitFn]="updateFn" [buttonText]="'Update Location Hours'"></s25-ng-bulk-edit-save>
    `,
    styles: [
        "s25-timepicker {max-width: 130px;} s25-timepicker .ngDropdownPaginated {max-width: 130px; position: absolute; z-index: 1;}",
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkLocationEditHoursComponent implements OnInit {
    @Input() itemTypeId: any = undefined;
    @Input() itemIds: any = undefined;
    prefType: any = "S";
    isTimeError: boolean = false;
    hours: any = [
        {
            day: "Monday",
            dayOfWeek: 1,
            open: new Date("1900-01-01 00:00"),
            close: new Date("1900-01-01 23:59"),
            openId: "openId1",
            closeId: "closeId1",
            update: false,
        },
        {
            day: "Tuesday",
            dayOfWeek: 2,
            open: new Date("1900-01-01 00:00"),
            close: new Date("1900-01-01 23:59"),
            openId: "openId2",
            closeId: "closeId2",
            update: false,
        },
        {
            day: "Wednesday",
            dayOfWeek: 3,
            open: new Date("1900-01-01 00:00"),
            close: new Date("1900-01-01 23:59"),
            openId: "openId3",
            closeId: "closeId3",
            update: false,
        },
        {
            day: "Thursday",
            dayOfWeek: 4,
            open: new Date("1900-01-01 00:00"),
            close: new Date("1900-01-01 23:59"),
            openId: "openId4",
            closeId: "closeId4",
            update: false,
        },
        {
            day: "Friday",
            dayOfWeek: 5,
            open: new Date("1900-01-01 00:00"),
            close: new Date("1900-01-01 23:59"),
            openId: "openId5",
            closeId: "closeId5",
            update: false,
        },
        {
            day: "Saturday",
            dayOfWeek: 6,
            open: new Date("1900-01-01 00:00"),
            close: new Date("1900-01-01 23:59"),
            openId: "openId6",
            closeId: "closeId6",
            update: false,
        },
        {
            day: "Sunday",
            dayOfWeek: 7,
            open: new Date("1900-01-01 00:00"),
            close: new Date("1900-01-01 23:59"),
            openId: "openId7",
            closeId: "closeId7",
            update: false,
        },
    ];
    initHours: any;

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit() {
        this.initHours = S25Util.deepCopy(this.hours);
        this.cd.detectChanges();
    }

    onChangeTime = (e: any, name: any, i: any) => {
        if (
            S25Util.date.toS25ISOTimeStr(this.hours[i].open) !== S25Util.date.toS25ISOTimeStr(this.initHours[i].open) ||
            S25Util.date.toS25ISOTimeStr(this.hours[i].close) !== S25Util.date.toS25ISOTimeStr(this.initHours[i].close)
        ) {
            this.hours[i].update = true;
        }
        // document.getElementById(name).checked = false;
        this.hours[i].open > this.hours[i].close ? (this.isTimeError = true) : (this.isTimeError = false);
        this.cd.detectChanges();
    };

    onUpdateChange(e: any, i: any) {
        this.hours[i].update = e.target.checked;
        this.initHours = S25Util.deepCopy(this.hours);
        this.cd.detectChanges();
    }

    onMouseOver(i: any) {
        if (
            S25Util.date.toS25ISOTimeStr(this.hours[i].open) !== S25Util.date.toS25ISOTimeStr(this.initHours[i].open) ||
            S25Util.date.toS25ISOTimeStr(this.hours[i].close) !== S25Util.date.toS25ISOTimeStr(this.initHours[i].close)
        ) {
            this.hours[i].update = true;
        }
        this.cd.detectChanges();
    }

    get updateFn() {
        return this.update.bind(this);
    }

    update() {
        if (this.isTimeError) {
            alert("Please select an open time after close time.");
        } else {
            let len = this.hours.length;
            let updateHours: any = [];
            for (let i = 0; i < len; i++) {
                if (this.hours[i].update) {
                    updateHours.push({
                        day_of_week: this.hours[i].dayOfWeek,
                        open_tm:
                            S25Datefilter.transform(this.hours[i].open, "YYYY-MM-dd") +
                            " " +
                            S25Util.date.toS25ISOTimeStr(this.hours[i].open),
                        close_tm:
                            S25Datefilter.transform(this.hours[i].close, "YYYY-MM-dd") +
                            " " +
                            S25Util.date.toS25ISOTimeStr(this.hours[i].close),
                    });
                }
            } // end for loop

            if (updateHours.length > 0) {
                return SpaceService.updateHours(this.itemIds, updateHours);
            }
        }
    } //END update

    close(e: any) {
        this.hours[e].update = true;
        this.hours[e].open = new Date("1900-01-01 00:00");
        this.hours[e].close = new Date("1900-01-01 00:00");
        TimepickerApi.init(this.elementRef.nativeElement);
    }
}
