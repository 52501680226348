//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { SpaceService } from "../../../services/space.service";
import { TypeManagerDecorator } from "../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-bulk-location-edit-scheduler")
@Component({
    selector: "s25-ng-bulk-location-edit-scheduler",
    template: `
        <span class="c-margin-top--single c-margin-right--half ngInlineBlock ngBold">Add/Remove Scheduler</span>
        <s25-toggle-button
            (modelValueChange)="this.onToggleChange($event)"
            [modelValue]="this.removeContact"
            [trueLabel]="trueLabel"
            [falseLabel]="falseLabel"
        ></s25-toggle-button>

        <div *ngIf="!this.removeContact" class="c-margin-top--single">
            <span class="ngBold">Select Scheduler</span>
            <s25-contact-dropdown
                [(chosen)]="this.contact"
                (chosenChange)="addScheduler($event)"
                [r25UserOnly]="true"
            ></s25-contact-dropdown>
        </div>
        <s25-ng-bulk-edit-save
            [submitFn]="updateFn"
            [buttonText]="this.removeContact ? 'Remove Scheduler' : 'Add Scheduler'"
        ></s25-ng-bulk-edit-save>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkLocationEditSchedulerComponent implements OnInit {
    @Input() itemTypeId: any = undefined;
    @Input() locationIds: any = undefined;
    @Input() falseLabel: string = "Add";
    @Input() trueLabel: string = "Remove";

    contact: any = "";
    removeContact: boolean = false;

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit() {
        this.cd.detectChanges();
    }

    onToggleChange(event: any) {
        this.removeContact = event;
        this.removeContact ? (this.contact = "") : "";
        this.cd.detectChanges();
    }

    addScheduler(c: any) {
        this.contact = c;
    }

    get updateFn() {
        return this.update.bind(this);
    }

    update() {
        if (this.contact === "" && !this.removeContact) {
            alert("Please select a scheduler.");
        } else {
            !this.removeContact ? (this.contact = this.contact.val) : "";
            return SpaceService.updateScheduler(this.locationIds, this.contact);
        }
    }
}
