//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { SpaceService } from "../../../services/space.service";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { S25BulkEditSaveApi } from "../save/s25.bulk.edit.save.api";

@TypeManagerDecorator("s25-ng-bulk-location-edit-layout-capacity")
@Component({
    selector: "s25-ng-bulk-location-edit-layout-capacity",
    template: `
        <div>
            <div *ngIf="this.type === 'layout'">
                <span id="create-new-layout-btn" class="c-margin-top--single c-margin-right--half ngBold ngInlineBlock"
                    >Create New Layout</span
                >
                <s25-toggle-button
                    (modelValueChange)="this.onCreateNewChange($event)"
                    [modelValue]="this.createNew"
                ></s25-toggle-button>

                <label *ngIf="!this.createNew">
                    <span class="ngBold ngBlock c-margin-top--single c-margin-bottom--quarter">Select Layout</span>
                    <s25-ng-dropdown-search-criteria
                        [type]="'locationLayouts'"
                        [(chosen)]="this.chosen"
                        (chosenChange)="onChosen()"
                    ></s25-ng-dropdown-search-criteria>
                </label>

                <label *ngIf="this.createNew" for="layoutName">
                    <span class="ngBold ngBlock c-margin-bottom--quarter">New Layout Name</span>
                    <input
                        class="c-input"
                        id="layoutName"
                        [(ngModel)]="this.layoutName"
                        type="text"
                        maxlength="80"
                        class="cn-form__control"
                    />
                </label>

                <div>
                    <span class="c-margin-top--single ngBold ngInlineBlock">Update Capacity for Existing</span>
                    <s25-toggle-button
                        (modelValueChange)="this.createCapacityChange($event)"
                        [modelValue]="this.capacityChange"
                    ></s25-toggle-button>
                </div>
            </div>

            <div *ngIf="this.capacityChange">
                <span class="c-margin-top--single c-margin-right--half ngBold ngInlineBlock"
                    >Calculate New {{ this.type === "layout" ? "Layout" : "Max" }} Capacity Using</span
                >
                <s25-toggle-button
                    (modelValueChange)="this.onFlatNumberChange($event)"
                    [trueLabel]="'Percentage'"
                    [falseLabel]="'Flat Number'"
                    [modelValue]="this.percentageOn"
                ></s25-toggle-button>

                <div *ngIf="!this.percentageOn">
                    <span class="c-margin-top--single c-margin-right--half ngBold ngInlineBlock"
                        >Add/Subtract Current {{ this.type === "layout" ? "Layout" : "Max" }} Capacity</span
                    >
                    <s25-toggle-button
                        (modelValueChange)="this.onToggleChange($event)"
                        [modelValue]="this.diffOn"
                    ></s25-toggle-button>

                    <div *ngIf="!this.diffOn">
                        <label for="capacity">
                            <span class="ngBold ngBlock c-margin-top--single c-margin-bottom--quarter"
                                >{{ this.type === "layout" ? "Layout" : "Location Max" }} Capacity</span
                            >
                            <input
                                type="number"
                                step="10"
                                [(ngModel)]="this.capacity"
                                name="capacity"
                                id="capacity"
                                (change)="onChange()"
                                class="cn-form__control"
                            />
                        </label>
                    </div>

                    <div *ngIf="this.diffOn">
                        <label for="diff">
                            <span class="ngBold ngBlock c-margin-bottom--quarter"
                                >+/- Current {{ this.type === "layout" ? "Layout" : "Max" }} Capacity</span
                            >
                            <input
                                type="number"
                                step="1"
                                [(ngModel)]="this.diff"
                                name="diff"
                                class="cn-form__control"
                                id="diff"
                                (change)="onChange()"
                            />
                        </label>
                    </div>
                </div>

                <div *ngIf="this.percentageOn">
                    <label class="c-margin-top--half capacityToggle" for="multiplier">
                        <span class="ngBold ngBlock c-margin-bottom--quarter"
                            >Percent of Current {{ this.type === "layout" ? "Layout" : "Max" }} Capacity</span
                        >
                        <input
                            type="number"
                            step="1"
                            min="{{ this.min }}"
                            max="{{ this.max }}"
                            [(ngModel)]="this.multiplier"
                            name="multiplier"
                            id="multiplier"
                            class="cn-form__control"
                        />
                    </label>
                    <div class="cn-alert cn-alert--info c-margin-bottom--single c-margin-top--none capacityToggle">
                        <div class="cn-alert__icon cn-icon c-red" name="alert--info">
                            <svg class="cn-svg-icon" role="img">
                                <title>Information Icon</title>
                                <use
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#info"
                                ></use>
                            </svg>
                        </div>
                        <div class="cn-alert__label">
                            <p>In cases of rounding, the capacity will be rounded down to the lower whole number.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="c-margin-top--single c-margin-bottom--single" *ngIf="this.type === 'layout'">
                <label for="isDefault">
                    <span class="ngBold ngBlock c-margin-bottom--quarter">Default Layout</span>
                    <input type="checkbox" id="isDefault" (change)="onIsDefaultChange($event)" /> Set as Default Layout
                </label>

                <div class="c-margin-top--single c-margin-bottom--single">
                    <span class="c-margin-top--single c-margin-right--half ngBold ngInlineBlock">Layout Image</span>
                    <s25-toggle-button
                        (modelValueChange)="this.onImageChange($event)"
                        [trueLabel]="'Add/Edit'"
                        [falseLabel]="'Remove'"
                    ></s25-toggle-button>
                    <span *ngIf="this.isImageEdit" class="ngInlineBlock">
                        <s25-ng-editable-image
                            [showImage]="true"
                            val=""
                            [onCommit]="this.imageAction"
                            (valueChange)="this.onChangeImage($event)"
                            autoSaveUpload="true"
                        ></s25-ng-editable-image>
                    </span>
                </div>

                <div class="c-margin-top--single c-margin-bottom--single">
                    <span class="c-margin-top--single c-margin-right--half ngBold ngInlineBlock">Layout Diagram</span>
                    <s25-toggle-button
                        (modelValueChange)="this.onDiagramChange($event)"
                        [trueLabel]="'Add/Edit'"
                        [falseLabel]="'Remove'"
                    ></s25-toggle-button>
                    <span *ngIf="this.isDiagramEdit" class="ngInlineBlock">
                        <s25-ng-editable-image
                            [showImage]="true"
                            val=""
                            [onCommit]="this.diagramAction"
                            (valueChange)="this.onDiagramChange($event)"
                            autoSaveUpload="true"
                        ></s25-ng-editable-image>
                    </span>
                </div>
            </div>

            <s25-ng-bulk-edit-save [submitFn]="updateFn" [(buttonText)]="buttonText"></s25-ng-bulk-edit-save>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25BulkLocationEditLayoutCapacityComponent implements OnInit {
    @Input() itemTypeId: any = undefined;
    @Input() locationIds: any = undefined;
    @Input() type: any = "cap";
    capacity: number;
    diff: number;
    capType: string;
    diffOn: boolean = false;
    multiplier = 100;
    min = 0;
    max = 1000;
    percentageOn: boolean = false;

    createNew = false;
    isDefault = false;
    //min = (this.type === "layout" ? -1 : 0);
    //max = (this.type === "layout" ? 100 : 10000);
    buttonText = "Update Capacity";
    layoutName = "";
    chosen: any;
    capacityChange = true;
    diagramId: number;
    imageId: number;
    isImageEdit: any;
    isDiagramEdit: any;

    constructor(
        private zone: NgZone,
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnInit() {
        this.min = this.type === "layout" ? -1 : 0;
        this.max = this.type === "layout" ? 100 : 10000;
        this.buttonText = this.type === "layout" ? "Update Layout" : "Update Capacity";
        this.cd.detectChanges();
    }

    onChange() {
        this.cd.detectChanges();
    }

    onCreateNewChange(event: any) {
        this.createNew = event;
        this.cd.detectChanges();
    }

    onFlatNumberChange(event: any) {
        this.percentageOn = event;
        this.cd.detectChanges();
    }

    onToggleChange(event: any) {
        this.diffOn = event;
        this.cd.detectChanges();
    }

    onIsDefaultChange(event: any) {
        this.isDefault = event.target.checked;
        this.cd.detectChanges();
    }

    createCapacityChange(event: any) {
        this.capacityChange = event;
        !event ? (this.multiplier = -1) : (this.multiplier = 100);
        this.cd.detectChanges();
    }

    onChosen() {
        this.chosen.itemId;
        S25BulkEditSaveApi.reset(this.elementRef.nativeElement);
    }

    get updateFn() {
        return this.update.bind(this);
    }

    update() {
        if (this.type === "layout") {
            if (!this.locationIds || !this.locationIds.length) {
                alert("Please select one or more locations.");
            } else if (!this.chosen && this.type === "layout" && !this.createNew) {
                alert("Please select a layout.");
            } else if (!this.layoutName && this.type === "layout" && this.createNew) {
                alert("Please enter a new layout name.");
            } else if (
                this.type === "layout" &&
                (this.multiplier > this.max || this.multiplier < this.min || !S25Util.isFloat(this.multiplier))
            ) {
                alert(
                    "Please enter a valid integer between " +
                        this.min +
                        " and " +
                        this.max +
                        ". Any negative value will skip capacity adjustment.",
                );
            } else if (this.isDiagramEdit && (!this.diagramId || this.diagramId === null)) {
                alert("Please select/add a diagram.");
            } else if (this.isImageEdit && (!this.imageId || this.imageId === null)) {
                alert("Please select/add an image.");
            } else if (this.capacityChange && !this.capacity) {
                alert("Please enter a layout capacity value.");
            } else {
                if (this.multiplier && this.percentageOn) {
                    return SpaceService.updateLayout(
                        !this.createNew ? this.chosen.itemId : null,
                        this.createNew ? this.layoutName : null,
                        this.locationIds,
                        parseFloat(S25Util.coalesce(this.multiplier, 100.0)) / 100.0,
                        this.isDefault,
                        "multiplier",
                        this.imageId,
                        this.diagramId,
                    );
                } else if (!this.diffOn && this.capacity) {
                    return SpaceService.updateLayout(
                        !this.createNew ? this.chosen.itemId : null,
                        this.createNew ? this.layoutName : null,
                        this.locationIds,
                        this.capacity,
                        this.isDefault,
                        "flat",
                        this.imageId,
                        this.diagramId,
                    );
                } else if (this.diffOn && this.diff) {
                    return SpaceService.updateLayout(
                        !this.createNew ? this.chosen.itemId : null,
                        this.createNew ? this.layoutName : null,
                        this.locationIds,
                        this.diff,
                        this.isDefault,
                        "diff",
                        this.imageId,
                        this.diagramId,
                    );
                } else if (!this.capacityChange) {
                    return SpaceService.updateLayout(
                        !this.createNew ? this.chosen.itemId : null,
                        this.createNew ? this.layoutName : null,
                        this.locationIds,
                        -1,
                        this.isDefault,
                        "multiplier",
                        this.imageId,
                        this.diagramId,
                    );
                } else {
                    if (this.imageId || this.imageId === null || this.diagramId || this.diagramId === null) {
                        return SpaceService.updateLayout(
                            !this.createNew ? this.chosen.itemId : null,
                            this.createNew ? this.layoutName : null,
                            this.locationIds,
                            -1,
                            this.isDefault,
                            "multiplier",
                            this.imageId,
                            this.diagramId,
                        );
                    } else {
                        console.log("No update");
                    }
                }
            }
            //percentage change
        } else if (this.percentageOn) {
            if (!this.multiplier) {
                alert("Please enter a percentage ");
            } else {
                return SpaceService.updateMaxCapacity(
                    this.locationIds,
                    parseFloat(S25Util.coalesce(this.multiplier, 100.0)) / 100.0,
                    "multiplier",
                );
            }
            //increment or decrement
        } else if (this.diffOn) {
            if (!this.diff) {
                alert("Please enter a valid integer.");
            } else {
                return SpaceService.updateMaxCapacity(this.locationIds, this.diff, "diff");
            }
            //standard capacity update
        } else {
            if (!this.capacity) {
                alert("Please enter a valid capacity.");
            } else {
                return SpaceService.updateMaxCapacity(this.locationIds, this.capacity, "flat");
            }
        }
    } // END update

    imageAction = (data: any) => {
        this.imageId = data.imageId;
    };

    diagramAction = (data: any) => {
        this.diagramId = data.imageId;
    };

    onImageChange(e: any) {
        this.isImageEdit = e;
        !this.isImageEdit ? (this.imageId = null) : "";
        this.cd.detectChanges();
    }

    onDiagramChange(e: any) {
        this.isDiagramEdit = e;
        !this.isDiagramEdit ? (this.diagramId = null) : "";
        this.cd.detectChanges();
    }
}
